<template>
  <div
    class="characteristic-brand-link"
    :class="customCalss"
    data-transaction-name="Go To Brand"
    @click="goToBrand"
    v-html="prepareValue"
  />
</template>

<script>
import { formatRegionUrl } from '$modules/shipping/utils/region-url';

export default {
  name: 'ABrandLink',
  props: {
    prepareValue: {
      type: String,
      default: ''
    },
    customCalss: {
      type: String,
      default: ''
    },
    brandLink: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  methods: {
    goToBrand () {
      this.$router.push(this.localizedRoute(formatRegionUrl(`/${this.brandLink?.brand_data.url_key}`)))
    }
  }
}
</script>
<style lang="scss" scoped>
div.characteristic-brand-link {
  color: var(--orange-hover);
  cursor: pointer;
}
</style>
