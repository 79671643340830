import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import { reviewStore } from './store'

export const ReviewModule: StorefrontModule = function ({ store }, fromCache) {
  if (fromCache?.review) {
    reviewStore.state = fromCache.review
  }

  store.registerModule('review', reviewStore)
}
